import resolveImage from "./resolveImage";

const resolvePageBuilder = blocks => {
  const resolvedBlocks = [];
  blocks.forEach(b => {
    const { uid, type } = b;
    switch (type) {
      case "hero":
        resolvedBlocks.push({
          ...b,
          type,
          uid,
        });
        break;
      case "stats":
        resolvedBlocks.push({
          ...b,
          type,
          uid,
        });
        break;
      case "imageCopy":
        resolvedBlocks.push({
          ...b,
          type,
          uid,
          image: resolveImage(b.image),
        });
        break;
      case "leadCopy":
        resolvedBlocks.push({
          ...b,
          type,
          uid,
        });
        break;
      case "image":
        resolvedBlocks.push({
          ...b,
          type,
          uid,
          image: resolveImage(b.image),
        });
        break;
      case "imageGrid":
        resolvedBlocks.push({
          ...b,
          type,
          uid,
          images: b.images.map(img => resolveImage([img])),
        });
        break;
      case "copyButtons":
        resolvedBlocks.push({
          ...b,
          type,
          uid,
        });
        break;
      case "buttons":
        resolvedBlocks.push({
          ...b,
          type,
          uid,
        });
        break;
      case "list":
        resolvedBlocks.push({
          ...b,
          type,
          uid,
        });
        break;
      default:
        break;
    }
  });
  return resolvedBlocks;
};

export default resolvePageBuilder;
